import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { passwordRegex } from "variables/regex";
import RoutesName from "variables/route";
import * as yup from "yup";
import { toast } from "react-toastify";
import { post_api } from "api";
import { useSearchParams } from "react-router-dom";
import { post_api_token } from "api";


const useResetPasswordHook = () => {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const { signin } = RoutesName;
  const { token } = useParams();

  const initialValues = {
    password: "",
    confirm: "",
  };
  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Required")
      .matches(
        passwordRegex,
        "Password must contain at least one letter, one number, one special character (!@#$%^&*), and be at least 8 characters long."
      ),
    confirm: yup
      .string()
      .required("Required")
      .oneOf([yup.ref("password")], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const payload = {
        password: values.password,
      };
      const { data, message }  = await post_api(`auth/reset-password?token=${token}`, payload);
      toast.success(message);
      console.log(data)
      navigate(signin);
    },
  });

  const { handleSubmit, handleBlur, handleChange, values, touched, errors } =
    formik;

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    touched,
    errors,
    handleShowPassword,
    showPassword,
    signin,
  };
};

export default useResetPasswordHook;
