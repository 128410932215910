import { post_api } from "api";
import { toast } from "react-toastify";
import { Card, Col, DatePicker, List, Radio, Row } from "antd";
import dayjs from "dayjs";
import { Input } from "reactstrap";
import { useEffect, useState } from "react";

const useSignUpHook = () => {
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [abn, setAbn] = useState("");
  const [buttonDisables, setButtonDisables] = useState(false);
  const [registrationData, setRegistrationData] = useState({
    business_entity_id: "",

    // Business Details
    full_registered_name: "",
    registered_abn: "",
    date_of_registration: "",
    state_or_territory_of_registration: "",

    // Additional Business Details
    source_of_capital: "",
    industry: "",
    website_url: "",
    social_media_url: "",
    office_phone: "",

    // Registered Office Address
    unit_number: "",
    street_number: "",
    street_name: "",
    street_type: "",
    town_suburb: "",
    state: "",
    postcode: "",
    country: "",

    // Your Role
    role: "director",

    // Directors
    directors: [],
  });
  const [errors, setErrors] = useState({});
  const validateInput = (name, value) => {
    if (!value) {
        setErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        return false;
    } else {
        setErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors[name];
            return newErrors;
        });
        return true;
    }
};
  useEffect(() => {
    const shouldDisable = Object.keys(errors).length > 0;
    setButtonDisables(shouldDisable);
  }, [errors]);

  useEffect(() => {
    if (current === 1) {
      const fieldsToValidate = ['full_registered_name', 'date_of_registration', 'state_or_territory_of_registration'];
      const validations = fieldsToValidate.map(field => {
        if (registrationData[field] === '') {
          return true; // indicates an error
        }
        return false;
      });
      validations.some(Boolean) ? setButtonDisables(true) : setButtonDisables(false);
    }
  
    if (current === 2) {
      const fieldsToValidate = ['source_of_capital', 'industry', 'website_url', 'social_media_url', 'office_phone'];
      const validations = fieldsToValidate.map(field => {
        if (registrationData[field] === '') {
          return true; // indicates an error
        }
        return false;
      });
  
      validations.some(Boolean) ? setButtonDisables(true) : setButtonDisables(false);
    }
    if (current === 3) {
      console.log(registrationData)
      const fieldsToValidate = ['unit_number', 'street_number', 'street_name', 'street_type', 'town_suburb', 'state', 'postcode', 'country'];
      const validations = fieldsToValidate.map(field => {
        if (registrationData[field] === '' || registrationData[field] === undefined) {
          return true; // indicates an error
        }
        return false;
      });
  
      validations.some(Boolean) ? setButtonDisables(true) : setButtonDisables(false);
    }
  }, [current, registrationData]);
  
  const handleChange = (name, value) => {
    setRegistrationData(prev => ({ ...prev, [name]: value }));
    validateInput(name, value);
  };

  const steps = [
    {
      id: "abn",
      title: "ABN",
      content: (
        <div>
          <Input placeholder="ABN" onChange={(e) => setAbn(e.target.value)} />
        </div>
      ),
    },
    {
      id: "business-details",
      title: "Business Details",
      content: (
        <div>
          <div className="pb-3">
            <label>Full Registered Business Name</label>
            <Input
              name="full_registered_name"
              placeholder="Full Registered Business Name"
              value={registrationData.full_registered_name}
              onChange={(e) => handleChange('full_registered_name', e.target.value)}
            />
            {errors.full_registered_name && <p style={{ color: 'red' }}>{errors.full_registered_name}</p>}
          </div>

          <div className="pb-3">
            <label>ABN </label>
            <Input
              name="registered_abn"
              placeholder="ABN"
              value={registrationData.registered_abn}
              disabled
            />
          </div>

          <div className="pb-3">
            <div>
              <label>Date of Registration</label>
            </div>
            <DatePicker
              defaultValue={dayjs(
                registrationData.date_of_registration,
                "YYYY-MM-DD"
              )}
              onChange={(date, dateString) => handleChange('date_of_registration', dateString)}
            />
          </div>

          <div>
            <label>State or Territory of Registration</label>
            <Input
              name="state_or_territory_of_registration"
              placeholder="State or Territory of Registration"
              value={registrationData.state_or_territory_of_registration}
              onChange={(e) => handleChange('state_or_territory_of_registration', e.target.value)}
            />
            {errors.state_or_territory_of_registration && <p style={{ color: 'red' }}>{errors.state_or_territory_of_registration}</p>}
          </div>
        </div>
      ),
    },
    {
      id: "additional-business-details",
      title: "Additional Business Details",
      content: (
        <div>
          <div className="pb-3">
            <label>Source of Capital</label>
            <Input
              name="source_of_capital"
              placeholder="Source of Capital"
              value={registrationData.source_of_capital}
              onChange={(e) => handleChange('source_of_capital', e.target.value)}
            />
            {errors.source_of_capital && <p style={{ color: 'red' }}>{errors.source_of_capital}</p>}
          </div>

          <div className="pb-3">
            <label>Industry</label>
            <Input
              name="industry"
              placeholder="Industry"
              value={registrationData.industry}
              onChange={(e) => handleChange('industry', e.target.value)}
            />
            {errors.industry && <p style={{ color: 'red' }}>{errors.industry}</p>}
          </div>

          <div className="pb-3">
            <label>Website URL</label>
            <Input
              name="website_url"
              placeholder="Website URL"
              value={registrationData.website_url}
              onChange={(e) => handleChange('website_url', e.target.value)}
            />
            {errors.website_url && <p style={{ color: 'red' }}>{errors.website_url}</p>}
          </div>

          <div className="pb-3">
            <label>Social Media URL (Optional)</label>
            <Input
              name="social_media_url"
              placeholder="Social Media URL (Optional)"
              value={registrationData.social_media_url}
              onChange={(e) => handleChange('social_media_url', e.target.value)}
            />
            {errors.social_media_url && <p style={{ color: 'red' }}>{errors.social_media_url}</p>}
          </div>

          <div>
            <label>Office Phone</label>
            <Input
              name="office_phone"
              placeholder="Office Phone"
              value={registrationData.office_phone}
              onChange={(e) => handleChange('office_phone', e.target.value)}
            />
            {errors.office_phone && <p style={{ color: 'red' }}>{errors.office_phone}</p>}
          </div>
        </div>
      ),
    },
    {
      id: "registerd-office-address",
      title: "Registerd Office Address",
      content: (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <div className="pb-3">
                <label>Unit No (Optional)</label>
                <Input
                  name="unit_no"
                  placeholder="Unit No (Optional)"
                  value={registrationData?.unit_number}
                  onChange={(e) =>
                    setRegistrationData((prev) => ({
                      ...prev,
                      unit_no: e.target.value,
                    }))
                  }
                />
              </div>
            </Col>
            <Col span={6}>
              <div className="pb-3">
                <label>Street Number</label>
                <Input
                  name="street_number"
                  placeholder="Street Number"
                  value={registrationData?.street_number}
                  onChange={(e) =>
                    setRegistrationData((prev) => ({
                      ...prev,
                      street_number: e.target.value,
                    }))
                  }
                />
              </div>
            </Col>
            <Col span={6}>
              <div className="pb-3">
                <label>Street Name</label>
                <Input
                  name="street_name"
                  placeholder="Street Name"
                  value={registrationData?.street_name}
                  onChange={(e) =>
                    setRegistrationData((prev) => ({
                      ...prev,
                      streetName: e.target.value,
                    }))
                  }
                />
              </div>
            </Col>
            <Col span={6}>
              <div className="pb-3">
                <label>Street Type</label>
                <Input
                  name="street_type"
                  placeholder="Street Type"
                  value={registrationData?.street_type}
                  onChange={(e) =>
                    setRegistrationData((prev) => ({
                      ...prev,
                      street_type: e.target.value,
                    }))
                  }
                />
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <div className="pb-3">
                <label>Town/Suburb</label>
                <Input
                  name="town_suburb"
                  placeholder="Town/Suburb"
                  value={registrationData?.town_suburb}
                  onChange={(e) =>
                    setRegistrationData((prev) => ({
                      ...prev,
                      town_suburb: e.target.value,
                    }))
                  }
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="pb-3">
                <label>State</label>
                <Input
                  name="state"
                  placeholder="State"
                  value={registrationData?.state}
                  onChange={(e) =>
                    setRegistrationData((prev) => ({
                      ...prev,
                      state: e.target.value,
                    }))
                  }
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="pb-3">
                <label>PostCode</label>
                <Input
                  name="postcode"
                  placeholder="PostCode"
                  value={registrationData?.postcode}
                  onChange={(e) =>
                    setRegistrationData((prev) => ({
                      ...prev,
                      postcode: e.target.value,
                    }))
                  }
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="pb-3">
                <label>Country</label>
                <Input
                  name="country"
                  placeholder="Country"
                  value={registrationData?.country}
                  onChange={(e) =>
                    setRegistrationData((prev) => ({
                      ...prev,
                      country: e.target.value,
                    }))
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      id: "your-role",
      title: "Your Role",
      content: (
        <div>
          <div className="pb-3">
            <div>
              <label>Your Role</label>
            </div>
            <Radio.Group
              defaultValue={registrationData.role}
              buttonStyle="solid"
              onChange={(e, v) =>
                setRegistrationData((prev) => ({
                  ...prev,
                  role: e.target.value,
                }))
              }
            >
              <Radio.Button
                value="director"
                style={{ display: "block", marginBottom: 3, borderRadius: 0 }}
              >
                Director
              </Radio.Button>
              <Radio.Button
                value="employee"
                style={{ display: "block", marginBottom: 3 }}
              >
                Employee
              </Radio.Button>
              <Radio.Button
                value="authorized_signatory"
                style={{ display: "block", marginBottom: 3 }}
              >
                Authorized Signatory
              </Radio.Button>
              <Radio.Button
                value="agent_contractor"
                style={{ display: "block", marginBottom: 3, borderRadius: 0 }}
              >
                Agent/Contractor
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      ),
    },
    {
      id: "directors",
      title: "Directors",
      content: (
        <div>
          <div className="pb-3">
            <div>
              <label>Directors</label>
            </div>
            <List
              grid={{
                gutter: 16,
                column: 4,
              }}
              dataSource={registrationData.directors}
              renderItem={(item) => (
                <List.Item>
                  <Card title={item.name}>
                    <div>
                      <label>Email: </label>
                      {item.emailAddress}
                    </div>
                    <div>
                      <label>DOB: </label>
                      {item.dateOfBirth}
                    </div>
                    <div>
                      <label>Address: </label>
                      {item.addressLongForm}
                    </div>
                  </Card>
                </List.Item>
              )}
            />
          </div>
        </div>
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const handleCheckABN = async () => {
    try {
      setLoading(true);
      const { data, message } = await post_api("auth/abnCheck", { abn });
      if(data?.businessDetails?.uboResponse?.business_details?.ABN){
        setRegistrationData((pre) => ({
          ...pre,
          full_registered_name:
            data?.businessDetails?.uboResponse?.business_details?.registered_name,
          registered_abn:
            data?.businessDetails?.uboResponse?.business_details?.ABN,
          date_of_registration:
            data?.businessDetails?.uboResponse?.business_details
              ?.date_registered_with_asic,
          state_or_territory_of_registration:
            data?.businessDetails?.uboResponse?.business_details
              ?.asic_company_type,
          unit_number:
            data?.businessDetails?.uboResponse?.business_details
              ?.registered_office?.unitNumber,
          street_number:
            data?.businessDetails?.uboResponse?.business_details
              ?.registered_office?.streetNumber,
          street_name:
            data?.businessDetails?.uboResponse?.business_details
              ?.registered_office?.streetName,
          street_type:
            data?.businessDetails?.uboResponse?.business_details
              ?.registered_office?.streetType,
          town_suburb:
            data?.businessDetails?.uboResponse?.business_details
              ?.registered_office?.town,
          state:
            data?.businessDetails?.uboResponse?.business_details
              ?.registered_office?.state,
          postcode:
            data?.businessDetails?.uboResponse?.business_details
              ?.registered_office?.postalCode,
          country:
            data?.businessDetails?.uboResponse?.business_details
              ?.registered_office?.country,
          directors: data?.ownerDetails?.officeHolders,
          business_entity_id:
            data?.businessDetails?.ownershipQueryResult?.entityId,
        }));
        toast.success(message);
        next();
      }else{
        toast.error('ABN details not found! Please register with correct ABN');
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  const handleClicks = (key) => {
    if (key === "abn") {
      handleCheckABN();
    } else {
      next();
    }
  };

  const handleRegister = async () => {
    try {
      setLoading(true);
      const { message } = await post_api("auth/register", registrationData);
      toast.success(message);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  return {
    loading,
    current,
    steps,
    items,
    abn,
    prev,
    handleClicks,
    handleRegister,
    buttonDisables,
  };
};

export default useSignUpHook;
