import React from "react";
import Dashboard from "views/Dashboard.js";
import RoutesName from "variables/route";

const { dashboard } = RoutesName;

var routes = [
  {
    path: dashboard,
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: <Dashboard />,
    inSidebar: true,
  },
];

export default routes;
