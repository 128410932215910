import { Spin } from "antd";
import { get_api } from "api";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(false);
  const getDashboardCounts = async () => {
    try {
      setIsLoading(true);
      const { data } = await get_api(`dashboard`);
      setDashboardData(data);
      setIsLoading(false);
      return data;
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getDashboardCounts();
  }, []);
  return (
    <>
      <div className="content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Row>
              <Col lg="4" md="12" sm="12">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-globe text-warning" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Total Balance</p>
                          <CardTitle tag="p">{dashboardData.company}$726.22</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="stats">
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                    <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-vector text-danger" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Total amount In Virtual GST</p>
                          <CardTitle tag="p">{dashboardData.locum}$726.22</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="stats">
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                    <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-money-coins text-success" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Amount Paid to ATO </p>
                          <CardTitle tag="p">
                            {dashboardData.postshift}$726
                          </CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="stats">
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
