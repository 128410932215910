import React from "react";
import { Link } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import useSignUpHook from "./useSignUpHook";
import { Steps } from "antd";

function SignUp() {
  const {
    loading,
    current,
    steps,
    items,
    abn,
    prev,
    handleClicks,
    handleRegister,
    buttonDisables,
  } = useSignUpHook();

  return (
    <div style={{ padding: 30 }}>
      <Steps
        current={current}
        items={items}
        labelPlacement="vertical"
        size="small"
      />
      <div style={{ padding: 20, marginTop: 10 }}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
          padding: 20,
        }}
      >
        {current < steps.length - 1 && (
          <Button
            disabled={!abn || loading || buttonDisables}
            onClick={() => handleClicks(steps[current].id)}
            size="small"
          >
            {loading ? (
              <>
                <Spinner size="sm" color="light" />
                {" Loading..."}
              </>
            ) : (
              "Next"
            )}
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button onClick={handleRegister} size="small">
            {loading ? (
              <>
                <Spinner size="sm" color="light" />
                {" Loading..."}
              </>
            ) : (
              "Done"
            )}
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            size="small"
            onClick={prev}
          >
            Previous
          </Button>
        )}
        <div style={{ display: "block" }}>
          Already have an account?
          <Link to={"/signin"} className="ml-1 fw-bold" type="Sign Up">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
